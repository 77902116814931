import { InboxUI } from "components";
import * as React from "react";

const Inbox = () => {
  return (
    <>
      <InboxUI />
    </>
  );
};

export { Inbox };
