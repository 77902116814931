import { RefundUI } from "components";
import * as React from "react";

const Refund = () => {
  return (
    <>
      <RefundUI />
    </>
  );
};

export { Refund };
