import { PrivacyUI } from "components";
import * as React from "react";

const Privacy = () => {
  return (
    <>
      <PrivacyUI />
    </>
  );
};

export { Privacy };
