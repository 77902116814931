import { TermsOfServiceUI } from "components";
import * as React from "react";

const TermsOfService = () => {
  return (
    <>
      <TermsOfServiceUI />
    </>
  );
};

export { TermsOfService };
